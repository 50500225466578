import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AddCard.css";

const AddCard = ({ createProduct }) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [info, setInfo] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState("");
  const [images, setImages] = useState({});

  function handleAddProduct() {
    if (!name || !price || !info || !image) {
      alert("Some inputs are empty");
      return;
    }

    const nonEmptyImages = Object.values(images).filter((img) => img !== "");

    let newProduct = {
      name,
      price,
      info,
      category,
      image,
      ...images,
    };
    createProduct(newProduct);

    setName("");
    setPrice("");
    setInfo("");
    setCategory("");
    setImage("");
    setImages({});
    navigate("/inventory");
  }

  function addExtraImageInput(e) {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newImages = {
          ...images,
          [`image${Object.keys(images).length + 1}`]: reader.result,
        };
        setImages(newImages);
      };
      reader.readAsDataURL(file);
    }
  }

  function renderImageInputs() {
    const imageInputs = [];
    for (let i = 1; i <= Object.keys(images).length + 1; i++) {
      imageInputs.push(
        <input
          key={i}
          type="text"
          placeholder={`image${i}`}
          value={images[`image${i}`] || ""}
          onChange={(e) =>
            setImages({ ...images, [`image${i}`]: e.target.value })
          }
        />
      );
    }
    return imageInputs;
  }

  return (
    <div className="add-card-container">
      <input
        type="text"
        placeholder="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type="text"
        placeholder="price"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
      />
      <textarea
        style={{
          height: "400px",
          resize: "none", // Отключаем возможность изменения размера
          overflowWrap: "break-word", // Применяем автоматический перенос текста
          width: "90%",
          backgroundColor: "black",
          color: "aliceblue",
        }}
        type="text"
        placeholder="info"
        value={info}
        onChange={(e) => setInfo(e.target.value)}
      />
      <input
        type="text"
        id="category"
        placeholder="category"
        value={category}
        onChange={(e) => setCategory(e.target.value)}
        list="categoriesList"
      />

      <datalist id="categoriesList">
        <option value="Available" />
        <option value="Sold Out" />
        <option value="Inbound" />
      </datalist>
      <input
        type="text"
        placeholder="image"
        value={image}
        onChange={(e) => setImage(e.target.value)}
      />
      {renderImageInputs()}
      {/* <input type="file" accept="image/*" onChange={addExtraImageInput} /> */}
      <button onClick={handleAddProduct} className="add-button">
        Add Product
      </button>
    </div>
  );
};

export default AddCard;
