import React, { useState, useEffect } from "react";
import Login from "../Login/Login";
import Inventory from "../Inventory/Inventory";
import Nav from "../Nav";
import { useAdminContext } from "../../adminContext";

const AdminPanel = () => {
  const { isAdminLoggedIn, setIsAdminLoggedIn } = useAdminContext();
  const [isLoggedOut, setIsLoggedOut] = useState(true);

  useEffect(() => {
    // При загрузке компонента, проверяем состояние входа в localStorage
    const storedAdminStatus = localStorage.getItem("isAdminLoggedIn");
    if (storedAdminStatus === "true") {
      setIsAdminLoggedIn(true);
      setIsLoggedOut(false);
    }
  }, []); // Пустой массив зависимостей, чтобы эффект выполнился только один раз при загрузке

  const handleLogin = (email, password) => {
    if (email === "admin1@gmail.com" && password === "0555224944") {
      setIsAdminLoggedIn(true);
      setIsLoggedOut(false);
      localStorage.setItem("isAdminLoggedIn", "true");
    } else {
      setIsAdminLoggedIn(false);
    }
  };

  const handleLogout = () => {
    setIsAdminLoggedIn(false);
    setIsLoggedOut(true);
    localStorage.removeItem("isAdminLoggedIn");
  };

  return (
    <>
      <Nav isAdminLoggedIn={isAdminLoggedIn} onAdminLogout={handleLogout} />
      {isLoggedOut ? (
        <Login onLogin={handleLogin} />
      ) : (
        <Inventory isAdmin={isAdminLoggedIn} onLogout={handleLogout} />
      )}
    </>
  );
};

export default AdminPanel;
