import React, { useEffect, useState } from "react";
import mainvideo2 from "../../assets/gg.MOV";
import "../Home/Home.css";

import { Link } from "react-router-dom";
const Home = () => {
  useEffect(() => {
    const videoElement = document.querySelector(".video");

    // Отключаем все возможные способы взаимодействия с видео на всех устройствах
    videoElement.controls = false;
    videoElement.disablePictureInPicture = true;
    videoElement.controlsList = "nodownload";
    videoElement.oncontextmenu = (e) => e.preventDefault();
    videoElement.onclick = (e) => e.preventDefault();
    videoElement.onplay = (e) => e.preventDefault();
    videoElement.onended = (e) => e.preventDefault();
    videoElement.oncanplay = (e) => e.preventDefault();
  }, []);
  return (
    <div className="glav_div">
      <div className="video-container">
        <video className="video" autoPlay loop muted>
          <source src={mainvideo2} type="video/mp4" />
        </video>
      </div>

      {/* <div className="home_video">
        <video autoPlay loop muted>
          <source src={mainvideo2} type="video/mp4" />
        </video>
      </div> */}
      {/* <div className="overlay2">
        <h1>Spirit Of The Nineties</h1>
        <button>View Inventory</button>
      </div>
      <div className="new_cars">
        <div className="cars_name">
          <h1 className="center">FEATURED INVENTORY</h1>
        </div> */}
      {/* <div className="cars_btn">
          <button className="green_button">New Arrival</button>
        </div> */}
      {/* <Link style={{ color: "aliceblue" }} to="/inventory">
          <div className="cars_cards">
            <div className="cars_card">
              <img
                src="https://static.wixstatic.com/media/de3284_91f773c6ab634885a81c4577c16490de~mv2.jpg/v1/fill/w_610,h_426,al_c,q_85,usm_0.66_1.00_0.01/de3284_91f773c6ab634885a81c4577c16490de~mv2.webp"
                alt=""
              />
              <div className="card_content">
                <h1 className="hover_title">
                  1997 Toyota Aristo V300 Vertex Edition <br />
                  18,995.00 Price
                </h1>
                <h1>New Arrival</h1>
              </div>
            </div>
            <div className="cars_card">
              <img
                src="https://static.wixstatic.com/media/de3284_607dcf8f82a042bcbb18cc2b6aec2dd6~mv2.jpg/v1/fill/w_610,h_406,al_c,q_85,usm_0.66_1.00_0.01/de3284_607dcf8f82a042bcbb18cc2b6aec2dd6~mv2.webp"
                alt=""
              />
              <div className="card_content">
                <h1 className="hover_title">
                  {" "}
                  1997 Toyota Aristo V300 Vertex Edition <br />
                  18,995.00 Price
                </h1>
                <h1>New Arrival</h1>
              </div>
            </div>
            <div className="cars_card">
              <img
                src="https://static.wixstatic.com/media/de3284_bdd4e98569f54e0f948d843b228de9c2~mv2.jpg/v1/fill/w_610,h_416,al_c,q_85,usm_0.66_1.00_0.01/de3284_bdd4e98569f54e0f948d843b228de9c2~mv2.webp"
                alt=""
              />
              <div className="card_content">
                <h1 className="hover_title">
                  1997 Toyota Aristo V300 Vertex Edition <br />
                  18,995.00 Price
                </h1>
                <h1>New Arrival</h1>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div className="view_btn">
        <button>View More Inventory</button>
      </div>
      <div id="about_loc">
        <div className="about_us">
          <h1>ABOUT US</h1>
          <p>
            Formerly known as AB Autos & Imports, Spirit Of The Nineties is a
            family owned business. We are a licensed and bonded car dealership
            located in Clearwater, Florida and have been involved in exporting
            cars from Japan for over 20 years. We have many, many more cars than
            listed on our website and several more arriving every month. Please
            feel free to contact us for any enquiries you might have about
            incoming cars, the importation and titling process, or anything else
            you'd like to know.
          </p>
        </div>
        <div className="location">
          {" "}
          <h1>LOCATION & HOURS</h1>
          <p>
            COME VISIT US AT: 14405 60th Street N. Clearwater, FL 33760 ​<br />
            WE ARE OPEN:
            <br />
            Tuesday 9AM - 5PM
            <br />
            Wednesday 9AM - 5PM
            <br />
            Thursday 9AM - 5PM
            <br />
            Friday 9AM - 5PM
            <br />
            Saturday 9AM - 5PM
            <br />
            Sunday CLOSED
            <br />
            Monday CLOSED
            <br />
            CALL US AT: (727) 286-8732
          </p>
        </div>
      </div>
      <div className="contact">
        <div className="contact_inp">
          <input type="text" placeholder="Name" />
          <input type="text" placeholder="Email" />
          <input type="text" placeholder="Subject" />
          <input type="text" placeholder="Messege" />
          <button>Send</button>
        </div>
        <div className="contact_info">
          {" "}
          <h1>Contact</h1>
          <p>
            Please fill out our contact form if you have <br /> any questions,
            concerns, or comments. A <br /> member of our team will get back to
            you <br />
            within 24 hours. You can also contact us at: ​
          </p>
          <p>Tel: (727) 286-8732</p>
        </div>
      </div>
      <div className="join">
        <h1>JOIN OUR MAILING LIST</h1>
        <h3>AND NEVER MISS AN UPDATE</h3>
        <input type="text" placeholder="Enter your email here*" />
        <button>SUBCRIBE NOW</button>
      </div>
      <div className="black"></div> */}
    </div>
  );
};

export default Home;
