import React, { useEffect } from "react";
import mainvideo2 from "../../assets/gg.MOV";
import "../Financing/Financing.css";

const Financing = () => {
  useEffect(() => {
    const videoElement = document.querySelector(".video");

    // Отключаем все возможные способы взаимодействия с видео на всех устройствах
    videoElement.controls = false;
    videoElement.disablePictureInPicture = true;
    videoElement.controlsList = "nodownload";
    videoElement.oncontextmenu = (e) => e.preventDefault();
    videoElement.onclick = (e) => e.preventDefault();
    videoElement.onplay = (e) => e.preventDefault();
    videoElement.onended = (e) => e.preventDefault();
    videoElement.oncanplay = (e) => e.preventDefault();
  }, []);
  return (
    <div>
      <video className="video" autoPlay loop muted controls>
        <source src={mainvideo2} type="video/mp4" />
      </video>
      <div className="nnff"></div>
      <div className="finc_glav">
        <div className="fin_glav">
          <div className="finan_glav">
            <h1>FINANCING</h1>
            <hr />
          </div>
          <div className="finan_glav2">
            <h1>
              YES! FINANCING IS <br /> AVAILABLE!
            </h1>
            <p>
              We do offer financing for these JDM CLASSICS. We can recommend a
              third party classic car lender that can get you <br />
              approved. Click below to apply online! <br />
              Basic Requirements
            </p>

            <li>10% to 20% Down Payment</li>
            <li>600 Credit Score</li>
            <li>3 Years of Credit History</li>
            <p>
              All applications are done case by case. The lender will provide
              all details of your application.
            </p>
            <p>CLICK HERE TO APPLY FOR FINANCING</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Financing;
