// Contact.js
import React, { useRef, useState, useEffect } from "react";
import "./Contact.css";
import emailjs from "emailjs-com";

const Contact = () => {
  const [showForm, setShowForm] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [email, setEmail] = useState("");
  const [currentMessage, setCurrentMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const form = useRef();

  // Загрузка сообщений из localStorage при загрузке компонента
  useEffect(() => {
    const storedMessages = localStorage.getItem("messages");
    console.log("Stored Messages:", storedMessages);
    if (storedMessages) {
      setMessages(JSON.parse(storedMessages));
    }
    setShowMessages(!!storedMessages); // Устанавливаем значение showMessages
  }, []);

  useEffect(() => {
    localStorage.setItem("messages", JSON.stringify(messages));
    console.log("Updated Messages:", messages);
    setShowMessages(messages.length > 0); // Устанавливаем значение showMessages
  }, [messages]);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_47tqnp3",
        "template_gbg6eab",
        form.current,
        "It2MhYVo-FjOaZGt1"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    const newMessage = {
      email,
      message: currentMessage,
      timestamp: new Date().toLocaleString(),
    };

    setMessages((prevMessages) => [newMessage, ...prevMessages]);
    setCurrentMessage("");
  };

  const handleButtonClick = () => {
    setShowForm(!showForm);
    setShowMessages(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "user_email") {
      setEmail(value);
    } else if (name === "user_message") {
      setCurrentMessage(value);
    }
  };

  return (
    <div className="contact-container">
      <button className="contact-button" onClick={handleButtonClick}>
        <img
          src="https://www.pngall.com/wp-content/uploads/10/Message-PNG-Image-HD.png"
          alt=""
        />
      </button>
      <div className={`contact-wrapper ${showForm ? "show-form" : ""}`}>
        <button className="close-button" onClick={handleButtonClick}>
          Let's Chat! <span className="close-icon">X</span>
        </button>
        <div className="message-container">
          {showMessages &&
            messages.map((msg, index) => (
              <div key={index} className="message-item">
                <div>{msg.email}</div>
                <div>{msg.message}</div>
                <div className="timestamp">{msg.timestamp}</div>
              </div>
            ))}
        </div>
        {showForm && (
          <form ref={form} onSubmit={sendEmail} className="contact-form">
            <div className="form-group">
              {/* <input
                type="email"
                name="user_email"
                className="form-input"
                placeholder="Введите ваш email"
                value={email}
                onChange={handleInputChange}
              /> */}
            </div>
            <div className="form-group">
              <input
                name="user_message"
                className="form-textarea"
                placeholder="Введите ваше сообщение"
                value={currentMessage}
                onChange={handleInputChange}
              />
            </div>
            <button type="submit" value="Send" className="form-button">
              Send
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Contact;
