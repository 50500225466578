import React, { useEffect, useState } from "react";
import "../Inventory/Inventory.css";
import { Link, useNavigate } from "react-router-dom";
import { JSON_API_PRODUCTS } from "../../helpers/const";

const Inventory = ({ isAdmin }) => {
  const [products, setProducts] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [visibleCount, setVisibleCount] = useState(18);
  const [mainImage, setMainImage] = useState("");
  const [productIdToDelete, setProductIdToDelete] = useState(0);

  const navigate = useNavigate();

  const handleExternalLink = (url) => {
    window.open(url, "_blank");
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(JSON_API_PRODUCTS);
        if (!response.ok) {
          throw new Error("Ошибка при получении данных");
        }
        const data = await response.json();
        setProducts(data);
        setCurrentPage(1);
        setSelectedCategory(null);
      } catch (error) {
        console.error("Ошибка при получении данных", error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const calculateVisibleProducts = () => {
      let filteredProducts = products;
      if (selectedCategory) {
        filteredProducts = products.filter(
          (product) => product.category === selectedCategory
        );
      }
      setVisibleProducts(filteredProducts.slice(-visibleCount).reverse());
    };

    calculateVisibleProducts();
  }, [products, selectedCategory, visibleCount]);

  const handleLoadMore = () => {
    setVisibleCount(visibleCount + 18);
  };

  const handleCategoryFilter = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1);
    setVisibleCount(18);
  };

  const handleImageClick = (image) => {
    setMainImage(image);
  };

  const deleteProduct = async (productId) => {
    try {
      const response = await fetch(`${JSON_API_PRODUCTS}/${productId}`, {
        method: "DELETE",
      });
      if (!response.ok) {
        throw new Error("Ошибка при удалении продукта");
      }
      setProducts((prevProducts) =>
        prevProducts.filter((product) => product.id !== productId)
      );
    } catch (error) {
      console.error("Ошибка при удалении продукта", error);
    }
  };

  const handleDeleteProduct = (productId) => {
    const shouldDelete = window.confirm(
      "Вы уверены, что хотите удалить этот продукт?"
    );
    if (shouldDelete) {
      deleteProduct(productId);
    }
  };

  const cancelDeleteProduct = () => {
    setProductIdToDelete(0);
  };

  const handleEditProduct = (productId) => {
    window.open(`/admin/editcard/${productId}`, "_blank");
  };

  return (
    <>
      <div className="inventory_glav">
        <h1>INVENTORY</h1>
        <h3>FINANCING AVAILABLE ON ALL VEHICLES</h3>
      </div>
      <div className="filter-container">
        <button
          className={`filter-button ${
            selectedCategory === "Available" ? "active" : ""
          }`}
          onClick={() => handleCategoryFilter("Available")}
        >
          Available
        </button>
        {/* ... (остальные кнопки для фильтрации без изменений) */}
        <button
          className={`filter-button ${
            selectedCategory === "Sold Out" ? "active" : ""
          }`}
          onClick={() => handleCategoryFilter("Sold Out")}
        >
          Sold Out
        </button>
        <button
          className={`filter-button ${
            selectedCategory === "Inbound" ? "active" : ""
          }`}
          onClick={() => handleCategoryFilter("Inbound")}
        >
          Inbound
        </button>
      </div>

      <div className="card-container2">
        {visibleProducts.map((product) => (
          <div key={product.id} className="card">
            {product.image === mainImage ? (
              <img src={mainImage} alt={product.name} />
            ) : (
              <img
                src={product.image}
                alt={product.name}
                onClick={() => handleImageClick(product.image)}
              />
            )}
            <div className="card-content">
              {isAdmin && (
                <>
                  <button onClick={() => handleEditProduct(product.id)}>
                    edit
                  </button>
                  <button onClick={() => handleDeleteProduct(product.id)}>
                    Delete
                  </button>
                </>
              )}
              <h2>{product.name}</h2>
              <p>{product.price}</p>
              <Link to={`/carsss/${product.id}`}>
                <button className="quick-view-button">Quick View</button>
              </Link>
            </div>
          </div>
        ))}
      </div>
      <div className="laod_div">
        {visibleCount < products.length && (
          <button className="load-more-button" onClick={handleLoadMore}>
            Load More
          </button>
        )}
      </div>
      <div className="gray_zone">
        {isAdmin && (
          <>
            <button onClick={() => handleExternalLink("/admin/addcard")}>
              Add product
            </button>
          </>
        )}
      </div>
      {productIdToDelete !== 0 && (
        <div className="modal-container">
          <div className="modal">
            <p>Вы уверены, что хотите удалить этот продукт?</p>
            <div className="modal-buttons">
              <button onClick={() => deleteProduct(productIdToDelete)}>
                Да
              </button>
              <button onClick={cancelDeleteProduct}>Нет</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Inventory;
