import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import "./CarsComponent.css";
import { Carousel } from "react-bootstrap";
import right from "../../assets/right.png";
import left from "../../assets/left.png";
import { JSON_API_PRODUCTS } from "../../helpers/const";

const CarsComponent = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [images, setImages] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(`${JSON_API_PRODUCTS}/${id}`);
        if (!response.ok) {
          throw new Error("Ошибка при получении данных");
        }
        const data = await response.json();
        setProduct(data);
        const imageKeys = Object.keys(data).filter((key) =>
          key.startsWith("image")
        );
        const imageUrls = imageKeys.map((key) => data[key]);
        setImages(imageUrls);
      } catch (error) {
        console.error("Ошибка при получении данных", error);
      }
    };

    fetchProduct();
  }, [id]);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await fetch(`${JSON_API_PRODUCTS}`);
        if (!response.ok) {
          throw new Error("Ошибка при получении данных");
        }
        const data = await response.json();
        setCards(data);
      } catch (error) {
        console.error("Ошибка при получении данных", error);
      }
    };

    fetchCards();
  }, []);

  const handleImageClick = (index) => {
    // Фильтруем только заполненные изображения
    const filledImages = images.filter((image) => !!image);
    setCurrentSlide(filledImages[index] ? index : 0);
  };
  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => prevSlide - 1);
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => prevSlide + 1);
  };
  const handlePrevSlides = () => {
    setCurrentSlide((prevSlides) => prevSlides - 1);
  };

  const handleNextSlides = () => {
    setCurrentSlide((prevSlides) => prevSlides + 1);
  };

  const visibleCards = cards
    .filter((card) => card.image && card.image !== "")
    .slice(currentSlide, currentSlide + 3);

  return (
    <div className="container">
      {product ? (
        <div className="images-container">
          <div className="ebal_slider">
            <div className="slider_prev" onClick={handlePrevSlides}>
              <img style={{ width: "70px" }} src={left} alt="" />
            </div>
            <div className="main-image-container">
              <img
                src={images[currentSlide]}
                alt="Основное изображение"
                className="main-image"
                onClick={handleNextSlides}
              />
            </div>
            <div className="slider_next" onClick={handleNextSlides}>
              <img style={{ width: "70px" }} src={right} alt="" />
            </div>
          </div>
          <div className="thumbnail-images-container">
            {images.map(
              (image, index) =>
                image &&
                image !== "" && (
                  <img
                    key={index}
                    src={image}
                    alt={`Миниатюра ${index}`}
                    className={`thumbnail-image ${
                      currentSlide === index ? "active" : ""
                    }`}
                    onClick={() => handleImageClick(index)}
                  />
                )
            )}
          </div>
        </div>
      ) : (
        <div>Загрузка изображений...</div>
      )}

      {product && (
        <div className="product-info">
          <div className="product-info-row">
            <h2 className="product-name">{product.name}</h2>
            <p className="product-info-text">{product.info}</p>
          </div>
          <div className="product-price">
            <p>{product.price}</p>
          </div>
        </div>
      )}
      {visibleCards.length > 0 && (
        <div>
          <div className="rela_h1">
            <h1>RELATED PRODUCTS</h1>
          </div>
          <div className="slider-container">
            <button className="slider-prev" onClick={handlePrevSlide}>
              <img style={{ width: "70px" }} src={left} alt="" />
            </button>
            <div className="card-slider">
              {visibleCards.map((card) => (
                <div key={card.id} className="card">
                  <img src={card.image} alt={card.name} />
                  <div className="card-content">
                    <h3>{card.name}</h3>
                    <p>{card.price}</p>
                    <Link to={`/carsss/${card.id}`}>
                      <button className="quick-view-button">Quick View</button>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <button className="slider-next" onClick={handleNextSlide}>
              <img style={{ width: "70px" }} src={right} alt="" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CarsComponent;
