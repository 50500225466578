import React from "react";
import AllRoutes from "./Routes/AllRoutes";
import Nav from "./Components/Nav";
import Rightmedia from "./Components/Rightmedia";

import Contact from "./Components/Contact";

const App = () => {
  return (
    <div>
      <Nav />
      <Contact />
      <Rightmedia />
      <AllRoutes />
    </div>
  );
};

export default App;
