import React, { useEffect, useRef } from "react";
import "../Location/Location.css";
import emailjs from "emailjs-com";
import mainvideo2 from "../../assets/gg.MOV";

const Location = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_47tqnp3",
        "template_a2a9obf",
        form.current,
        "It2MhYVo-FjOaZGt1"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  useEffect(() => {
    const videoElement = document.querySelector(".video");

    // Отключаем все возможные способы взаимодействия с видео на всех устройствах
    videoElement.controls = false;
    videoElement.disablePictureInPicture = true;
    videoElement.controlsList = "nodownload";
    videoElement.oncontextmenu = (e) => e.preventDefault();
    videoElement.onclick = (e) => e.preventDefault();
    videoElement.onplay = (e) => e.preventDefault();
    videoElement.onended = (e) => e.preventDefault();
    videoElement.oncanplay = (e) => e.preventDefault();
  }, []);
  return (
    <div className="location_glav">
      <video className="video" autoPlay loop muted>
        <source src={mainvideo2} type="video/mp4" />
      </video>
      <div className="loc_glav">
        <div className="location">
          <h1>CONTACT</h1>
          <hr />
        </div>
        <div className="location2">
          <div className="location_left">
            <h1>
              1050 American Way <br />
              Kissimmee, FL 34741
            </h1>
            <p>
              Email: info.jdmauto@gmail.com <br />
              Tel: (407)371-9417
            </p>
          </div>
          <div>
            <form className="location_right" ref={form} onSubmit={sendEmail}>
              <input
                name="user_name"
                className="inpu1"
                type="text"
                placeholder="Name"
              />
              <input
                name="user_email"
                className="inpu1"
                type="text"
                placeholder="Email"
              />
              <input
                name="user_phone"
                className="inpu1"
                type="text"
                placeholder="Phone"
              />
              <input
                name="user_message"
                className="input_loc"
                type="text"
                placeholder="Type your message here..."
              />
              <button type="submit" value="Send">
                Submit
              </button>
            </form>
          </div>
        </div>
        <div className="loc_map">
          <iframe
            className="iframe_map"
            // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10986.411974582315!2d-81.44697120863832!3d28.277895485383986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88dd839688f61e37%3A0x57f9f54fc840a291!2zMTA1MCBBbWVyaWNhbiBXYXksIEtpc3NpbW1lZSwgRkwgMzQ3NDEsINCh0KjQkA!5e0!3m2!1sru!2skg!4v1690398145538!5m2!1sru!2skg"
            // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3513.5526297917477!2d-81.4469326!3d28.281579099999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88dd830e1cdf04f5%3A0x6518f0c1fed9fb80!2sJDM%20Auto!5e0!3m2!1sru!2skg!4v1693889823138!5m2!1sru!2skg"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3513.5526297917477!2d-81.4469326!3d28.281579099999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88dd830e1cdf04f5%3A0x6518f0c1fed9fb80!2sJDM%20Auto!5e0!3m2!1sen!2skg!4v1694532979327!5m2!1sen!2skg"
            width="600"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Location;
