import React, { useState, useEffect } from "react";
import "./Navbar.css";
import login from "../assets/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/Aslan.png";

const Nav = ({ isAdminLoggedIn, onAdminLogout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrollDown, setScrollDown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setScrollDown(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleAdminLinkClick = (e) => {
    if (location.pathname.startsWith("/admin")) {
      e.preventDefault();
      navigate(e.target.getAttribute("to"));
    }
  };

  return (
    <div
      className={`navbar ${isMenuOpen ? "active" : ""} ${
        scrollDown ? "scroll-down" : ""
      }`}
    >
      <div className={`nav_logo ${scrollDown ? "scroll-down" : ""}`}>
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className="nav_btn">
        <Link to="/" className={location.pathname === "/" ? "active-link" : ""}>
          <h4>HOME</h4>
        </Link>
        {isAdminLoggedIn ? (
          <Link
            to="/inventory"
            className={location.pathname === "/inventory" ? "active-link" : ""}
          >
            <h4>INVENTORY</h4>
          </Link>
        ) : (
          <Link to="/inventory">
            <h4>INVENTORY</h4>
          </Link>
        )}
        <Link
          to="/financing"
          className={location.pathname === "/financing" ? "active-link" : ""}
        >
          <h4>FINANCING</h4>
        </Link>
        <Link
          to="/location"
          className={location.pathname === "/location" ? "active-link" : ""}
        >
          <h4>LOCATION & CONTACT</h4>
        </Link>
        {/* Добавьте другие ссылки здесь */}
      </div>
      <div className="nav_login">
        <Link to={isAdminLoggedIn ? "/" : "/admin"}>
          <img src={login} alt="" />
        </Link>
        {isAdminLoggedIn ? <h4>Log Out</h4> : <h4>Log In</h4>}
      </div>
      <div className="burger_icon" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </div>
  );
};

export default Nav;
