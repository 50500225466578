import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { JSON_API_PRODUCTS } from "../helpers/const";

const EditCard = ({ oneProduct, getOneProduct, editProduct }) => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [info, setInfo] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState("");
  const [images, setImages] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    getOneProduct(id);
  }, [id]);

  useEffect(() => {
    if (oneProduct) {
      setName(oneProduct.name);
      setPrice(oneProduct.price);
      setInfo(oneProduct.info);
      setCategory(oneProduct.category);
      setImage(oneProduct.image);
      const imagesArray = [
        oneProduct.image1 || "",
        oneProduct.image2 || "",
        oneProduct.image3 || "",
        oneProduct.image4 || "",
        oneProduct.image5 || "",
        oneProduct.image6 || "",
        oneProduct.image7 || "",
        oneProduct.image8 || "",
        oneProduct.image9 || "",
        oneProduct.image10 || "",
      ];
      setImages(imagesArray.filter((img) => img.trim() !== ""));
    }
  }, [oneProduct]);

  async function handleSaveButton() {
    let editedProduct = {
      name,
      price,
      info,
      category,
      image,
      ...getImagesObject(),
    };
    console.log("Saving edited product:", editedProduct);
    await updateProduct(id, editedProduct);
  }

  function getImagesObject() {
    const imagesObject = {};
    images.forEach((imageValue, index) => {
      imagesObject[`image${index + 1}`] = imageValue;
    });
    return imagesObject;
  }

  async function updateProduct(id, productData) {
    try {
      console.log("Updating product with ID:", id);
      console.log("Product data:", productData);

      const response = await axios.put(
        `${JSON_API_PRODUCTS}/${id}`,
        productData
      );
      console.log("Product updated successfully", response.data);
      // Можете выполнить какие-либо дополнительные действия при успешном обновлении
    } catch (error) {
      console.error("Error updating product", error);
      // Обработка ошибки при обновлении
    }
  }

  function handleImageChange(index, value) {
    console.log("Changing image at index", index, "to value:", value);
    const updatedImages = [...images];
    updatedImages[index] = value;
    setImages(updatedImages);
  }

  function handleAddImageInput() {
    console.log("Adding new image input");
    setImages([...images, ""]);
  }

  return (
    <div className="add-card-container">
      <input
        type="text"
        placeholder="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type="text"
        placeholder="price"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
      />
      <textarea
        style={{
          height: "400px",
          resize: "none", // Отключаем возможность изменения размера
          overflowWrap: "break-word", // Применяем автоматический перенос текста
          width: "90%",
          backgroundColor: "black",
          color: "aliceblue",
        }}
        placeholder="info"
        value={info}
        onChange={(e) => setInfo(e.target.value)}
      />
      <input
        type="text"
        id="category"
        placeholder="category"
        value={category}
        onChange={(e) => setCategory(e.target.value)}
        list="categoriesList"
      />

      <datalist id="categoriesList">
        <option value="Available" />
        <option value="Sold Out" />
        <option value="Inbound" />
      </datalist>
      <input
        type="text"
        placeholder="image"
        value={image}
        onChange={(e) => setImage(e.target.value)}
      />
      {images.map((imageValue, index) => (
        <input
          key={index}
          type="text"
          placeholder={`image${index + 1}`}
          value={imageValue}
          onChange={(e) => handleImageChange(index, e.target.value)}
        />
      ))}
      <button onClick={handleAddImageInput}>Add Image</button>
      <button onClick={handleSaveButton} className="add-button">
        Save
      </button>
    </div>
  );
};

export default EditCard;
