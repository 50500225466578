import React from "react";
import facebook from "../assets/facebook.png";
import youtube from "../assets/youtube.png";
import instagram from "../assets/instagram.png";
import "../Components/Rightmedia.css";

const Rightmedia = () => {
  const handleExternalLink = (url) => {
    window.open(url, "_blank");
  };
  return (
    <div className="media">
      {/* <a className="face_book" href="https://www.facebook.com">
        <img src={facebook} alt="Facebook" />
      </a> */}

      <a
        className="you_tube"
        onClick={() =>
          handleExternalLink("https://www.youtube.com/@jdmautollc3231")
        }
      >
        <img src={youtube} alt="YouTube" />
      </a>

      <a
        className="you_tube"
        onClick={() =>
          handleExternalLink("https://www.instagram.com/jdmautollc/")
        }
      >
        <img src={instagram} alt="Instagram" />
      </a>
    </div>
  );
};

export default Rightmedia;
