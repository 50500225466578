import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../Components/Home/Home";
import Inventory from "../Components/Inventory/Inventory";
import Login from "../Components/Login/Login";
import CarsComponent from "../Components/CarsComponent/CarsComponent";
import AdminPanel from "../Components/Admin/AdminPanel";
import ContactForm from "../Components/ContactForm/ContactForm";
import AddCard from "../Components/AddCard";
import EditCard from "../Components/EditCard";
import axios from "axios";
import { JSON_API_PRODUCTS } from "../helpers/const";
import Location from "../Components/Location/Location";
import Financing from "../Components/Financing/Financing";

const AllRoutes = () => {
  const [oneProduct, setOneProduct] = useState(null);

  async function getOneProduct(id) {
    let { data } = await axios(`${JSON_API_PRODUCTS}/${id}`);
    setOneProduct(data);
  }

  async function editProduct(id, editedProduct) {
    await axios.patch(`${JSON_API_PRODUCTS}/${id}`, editedProduct);
  }

  async function createProduct(newProduct) {
    await axios.post(JSON_API_PRODUCTS, newProduct);
  }

  return (
    <div>
      <Routes>
        <Route path="/location" element={<Location />} />
        <Route path="/admin/*" element={<AdminPanel />} />
        <Route path="/financing" element={<Financing />} />
        <Route path="/" element={<Home />} />
        <Route path="/inventory" element={<Inventory />} />
        <Route path="/login" element={<Login />} />
        <Route path="/carsss/:id" element={<CarsComponent />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route
          path="/admin/addcard"
          element={<AddCard createProduct={createProduct} />}
        />
        <Route
          path="/admin/editcard/:id"
          element={
            <EditCard
              getOneProduct={getOneProduct}
              oneProduct={oneProduct}
              editProduct={editProduct}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default AllRoutes;
